<template>
  <div>
    <Card>
      <template #title>Vínculos</template>
      <template #content>
        <DataTable
          class="p-datatable-sm"
          :paginator="true"
          :rows="5"
          stripedRows
          :loading="loading"
          :value="vinculos"
          dataKey="id"
          :filters.sync="filtros"
          :globalFilterFields="['nome']"
          filterDisplay="menu"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25]"
          currentPageReportTemplate="Exibindo {first} a {last} de {totalRecords} vínculos"
          responsiveLayout="scroll">
          <template #header>
            <span class="mt-2 mr-2 p-input-icon-left">
              <i class="pi pi-search" />
              <InputText
                v-model="filtros['global'].value"
                placeholder="Pesquisar"
                style="width: 100%" />
            </span>
            <Button
              icon="pi pi-filter-slash"
              label="Limpar"
              class="mt-2 mr-2 p-button-outlined"
              @click="limparFiltro" />
            <Button
              label="Novo"
              icon="pi pi-plus"
              class="mt-2 mr-2 p-button-success float-right"
              @click="showFormVinculo()" />
          </template>
          <Column field="id" header="ID"></Column>
          <Column field="nome" header="Nome"></Column>
          <Column header="Ações">
            <template #body="slotProps">
              <Button
                v-tooltip.left="'Editar'"
                icon="pi pi-pencil"
                class="p-button-rounded p-button-warning mr-2"
                @click="showFormVinculo(slotProps.data)" />
            </template>
          </Column>
        </DataTable>
      </template>
      <template #footer> </template>
    </Card>
    <VinculoForm
      :visible.sync="showVinculoFormDialog"
      :propsvinculo="vinculo"
      :update="update"
      @carregarDados="carregarDados"
      @onUpdate="onUpdateVinculo" />
  </div>
</template>

<script>
import { FilterMatchMode, FilterOperator } from 'primevue/api/'
import VinculoForm from './VinculoForm.vue'
import VinculoService from '@/service/VinculoService.js'

export default {
  components: {
    VinculoForm,
  },
  data() {
    return {
      filtros: {},
      vinculo: {},
      vinculos: null,
      loading: false,
      showVinculoFormDialog: false,
      update: false,
    }
  },

  created() {
    this.vinculoService = new VinculoService(this.$http)
    this.initFiltros()
  },

  mounted() {
    this.carregarDados()
  },

  methods: {
    showFormVinculo(data) {
      if (data) {
        this.vinculo = data
        this.update = true
      } else {
        this.vinculo = {}
        this.update = false
      }
      this.showVinculoFormDialog = true
    },

    onUpdateVinculo(event) {
      let index = this.vinculos.findIndex((v) => v.id === event.id)
      index != -1
        ? this.$set(this.vinculos, index, event)
        : this.vinculos.unshift(event)
    },
    limparFiltro() {
      this.initFiltros()
    },

    initFiltros() {
      this.filtros = {
        global: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.CONTAINS },
            { value: null, matchMode: FilterMatchMode.EQUALS },
          ],
        },
      }
    },

    addVinculo() {
      this.$router.push({ name: 'vinculo-novo' })
    },

    carregarDados() {
      this.loading = true
      this.vinculoService.getListaVinculos().then((res) => {
        this.vinculos = res
        this.loading = false
      })
    },

    editVinculo(data) {
      this.$router.push({ name: 'vinculo-edit', params: { id: data.id } })
    },
  },
}
</script>

<style></style>
